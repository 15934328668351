.PageHome-section--butts {
  position: relative;
}

.SectionMint {
  text-align: center;
}

.SectionMint-headline {
  margin-bottom: 1em;
}

.SectionMint-section {
  margin-bottom: 3em;
  margin-top: 2em;
}

.SectionMint-section:last-child {
  margin-bottom: 0;
}

.SectionMint-sectionHeadline {
  display: block;
  font-size: 0.8em;
  font-weight: 300;
  font-family: "Press Start 2P";
  text-transform: uppercase;
  margin-bottom: 0.6em;
}

.SectionMint-inputNoteWrapper {
  position: relative;
  display: inline-block;
}

.SectionMint-value {
  font-size: 1.5em;
}

.SectionMint-inputLabel {
  position: relative;
  display: inline-block;
}

.SectionMint-inputLabel .SectionMint-sectionHeadline {
  margin-bottom: 1.25em;
}

.SectionMint-inputNote {
  /* position: absolute;
  left: 100%; */
  position: relative;
  top: 0;
  margin-top: 0.1em;
  white-space: nowrap;
  font-size: 1.5em;
  font-weight: 700;
  margin-left: 1.333em;
}

.SectionMint-inputNote::before {
  content: "x";
  font-weight: 300;
  margin: 0 0.333em 0 0.5em;
  text-decoration: none;
  position: absolute;
  right: 100%;
  margin-top: -0.05em;
}

.SectionMint-inputNote--discount {
  text-decoration: line-through;
}

.SectionMint-inputNoteNewPrice {
  content: '';
  text-decoration: none;
  position: absolute;
  left: 100%;
  color: red;
  margin-left: 0.25em;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 540px) {

  .SectionMint-inputNoteNewPrice {
    left: initial;
    right: 0;
    top: 100%;
    transform: none;
  }
}

.SectionMint-section--confirm {
  margin-top: 4em;
}

.SectionMint-extendedInputInner {
  background-color: #f2f2f2;
  padding: 0 1em 0 0.5em;
  font-size: 16px;
}

.SectionMint-input {
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid #000;
  padding: 1px 2px 1px 0.75em;
  box-shadow: 0;
  outline: 0;
  font-weight: 700;
}

.SectionMint-section--connect {
  margin-top: 0;
}

.SectionMint-section--connect .SectionMint-headline {
  margin-bottom: 2em;
}

.SectionMint-message {
  display: block;
  max-width: 30em;
  font-size: 1.25em;
  margin: 0 auto;
  background-color: #FFFCF9;
  border: 1px solid transparent;
  padding: 1em;
  border-radius: 0.25em;
}

.SectionMint-message--error {
  color: red;
  border-color: red;
}

.SectionMint-message--success {
  color: green;
  border-color: green;
}