.Accordion {
  display: block;
}

.Accordion-title {
  position: relative;
  font-family: "Press Start 2P";
  text-transform: uppercase;
  font-weight: 400;
  display: block;
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  padding: 0.25em 0;
}

.Accordion-title::after {
  content: '>';
  text-transform: lowercase;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center;
  transform: rotate(90deg);
  letter-spacing: 0;
}

.Accordion--open .Accordion-title::after {
  transform: rotate(-90deg);
}

.Accordion--closed .Accordion-content {
  display: none;
}

.Accordion-content {
  margin-top: 0.5em;
}