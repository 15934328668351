.PageGallery {
  flex: 1;
  display: flex;
  background: linear-gradient(0deg, #fc0, #0000);
}

.PageGallery-inner {
  flex: 1;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: center;
  padding: 2em 1em 0;
  margin: 0 auto;
  max-width: 1856px;
}

@media only screen and (min-width: 2280px) {}

.PageGallery-main {
  position: relative;
  width: 100%;
}

.PageGallery-tokenList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 14px;
  row-gap: 14px;
  width: 100%;
}

@media only screen and (max-width: 1280px) {
  .PageGallery-tokenList {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 1024px) {
  .PageGallery-tokenList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 500px) {
  .PageGallery-tokenList {
    grid-template-columns: 1fr;
  }
}

.PageGallery-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5em;
}

.PageGallery-count {
  font-family: "Press Start 2P";
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.75em;
  margin-right: 1em;
}

.PageGallery-activeFilterList {
  text-align: left;
}

.PageGallery-activeFilter {
  text-transform: uppercase;
  margin-bottom: 0.5em;
  margin-right: 0.5em;
  font-size: 0.7em;
  border-radius: 8px;
  padding: 3px 8px;
  background-color: #fc0;
  border: 1px solid #0000001a;
  cursor: pointer;
}

.PageGallery-activeFilter::after {
  content: 'x';
  margin-left: 0.6667em;
  font-weight: bold;
}

.PageGalleryTraitFilter {
  padding: 0 1.7em;
  margin-bottom: 0.333em;
}

.PageGalleryTraitFilter-label {
  display: block;
  position: relative;
  padding-right: 6em;
  padding-left: 2em;
}

.PageGalleryTraitFilter-input {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
}

.PageGalleryTraitFilter-count {
  position: absolute;
  top: 0;
  right: 0;
}

.PageGallery-input {
  color: #000;
  background-color: rgba(255, 255, 255, 0);
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  padding: 0.7em 1.5em 0.4em;
  box-shadow: none;
  outline: 0;
  width: 100%;
  margin-bottom: 1em;
  font-weight: 400;
  font-family: "Press Start 2P";
  text-transform: uppercase;
  /* font-size: 0.75em; */
}

.PageGallery-input::placeholder {
  color: #000;
  opacity: 0.25;
}

.PageGallery-section {
  margin-bottom: 2.25em;
  margin-top: 2.25em;
}

.PageGalleryControls-title {
  font-size: 1.25em;
  line-height: 1.25em;
  padding: 0 1.5em;
  margin: 0 0 1em;
  font-family: "Press Start 2P";
  text-transform: uppercase;
  font-weight: 400;
}

/* .PageGalleryControls-title::before {
  content: '';
  background-image: url('../../media/filter-icon.svg');
  background-size: contain;
  background-position: center;
  width: 1.149em;
  height: 1em;
  display: inline-block;
  margin-right: 0.8em
} */

.PageGallery-inputLabel .PageGallery-sectionHeadline {
  margin-bottom: 1em;
}

.PageGallery-extendedInput {
  border-radius: 8px;
  display: inline-block;
  background-color: #f2f2f2;
}

.PageGallery-extendedInputInner {
  background-color: #f2f2f2;
  padding: 0 1em 0 0.5em;
  font-size: 16px;
}

.PageGalleryImg {
  cursor: pointer;
  position: relative;
  display: inline-block;
  border: none;
  background-color: transparent;
  width: 100%;
  text-decoration: none;
  color: #000;
  padding: 0;
  backface-visibility: hidden;
  transition: border-color 0.2s, background-color 0.2s;
}

@media only screen and (max-width: 500px) {
  .PageGalleryImg {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.PageGalleryImg-wrapper {
  width: 100%;
  /* max-width: 336px; */
  position: relative;
  background-color: rgba(255, 255, 255, 0.25);
  transition: transform 0.2s;
  border-radius: 8px;
  overflow: hidden;
}

.PageGalleryImg:hover .PageGalleryImg-wrapper {
  transform: scale(1.025);
}

.PageGalleryImg:active .PageGalleryImg-wrapper {
  transform: scale(1.01);
}

.PageGalleryImg-wrapper::before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 100%;
}

.PageGalleryImg-label {
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-weight: 400;
  font-family: "Press Start 2P";
  font-size: 1em;
  padding: 0.6667em;
}

@media only screen and (max-width: 1280px) {
  .PageGalleryImg-label {
    font-size: 0.75em;
  }
}

.PageGalleryImg-roleTraits {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 3em;
}

.PageGalleryImg--owned::after {
  content: "OWNED";
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  color: #FFFCF9;
  background-color: #ff0000;
  padding: 0.4em 0em 0.4em 0.667em;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-weight: 400;
  font-family: "Press Start 2P";
  font-size: 0.75em;
}

.PageGalleryImg-img {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.PageGallery-controls {
  padding: 2em 0 1.5em;
  overflow: auto;
  height: 100%;
}

.PageGalleryControls {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
}

.PageGalleryControls--expanded {
  pointer-events: initial;
}

.PageGalleryControls-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s;
  border: 0;
  background: transparent;
}

.PageGalleryControls--expanded .PageGalleryControls-overlay {
  background-color: #ffffffbf;
}

.PageGalleryControls-drawer {
  text-align: left;
  background-color: #FFFCF9;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  z-index: 1;
  transition: transform 0.2s;
  max-width: calc(100% - 30px);
  width: 360px;
  background: #fff;
  border-width: 0 0 0 2px;
  border-style: solid;
  border-color: #0000001a;
  /* border-width: 2px 0 2px 2px;
  border-style: solid;
  border-color: #fff; */
}

.PageGalleryControls--expanded .PageGalleryControls-drawer {
  transform: translateX(-100%);
}

.PageGalleryControls-trigger {
  position: absolute;
  font-size: 30px;
  right: 100%;
  top: 254px;
  padding: 0.333em 0.25em 0.333em 0.5em;
  border-radius: 8px 0 0 8px;
  background-color: #fc0;
  cursor: pointer;
  margin: 0;
  font-weight: 300;
  font-family: "Press Start 2P";
  text-transform: uppercase;
  white-space: nowrap;
  border-width: 2px 0 2px 2px;
  border-style: solid;
  border-color: #0000001a;
  pointer-events: initial;
}

@media only screen and (max-width: 800px) {
  .PageGalleryControls-trigger {
    top: 25%;
  }
}

.PageGalleryControls-triggerIcon {
  background-image: url('../../media/filter-icon.svg');
  background-size: contain;
  background-position: center;
  width: 1em;
  height: 0.87em;
  display: block;
}

@media only screen and (min-width: 800px) {
  .PageGalleryControls {
    position: static;
    pointer-events: initial;
  }

  .PageGalleryControls-overlay {
    display: none;
  }

  .PageGalleryControls-drawer,
  .PageGalleryControls--expanded .PageGalleryControls-drawer {
    position: static;
    transform: none;
  }

  .PageGalleryControls-drawer {
    border-width: 1px;
  }

  .PageGalleryControls-trigger {
    display: none;
  }
}

.DiscordRoles {
  padding: 1em 1.7em 0;
  margin-top: 1em;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #0000001a;
}

.DiscordRoles-title {
  font-weight: 300;
  font-family: "Press Start 2P";
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

.DiscordRoles-desc {
  margin-bottom: 0.6667em;
}

.DiscordRoles-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.PageGallery-checkbox {
  font-weight: 300;
  font-family: "Press Start 2P";
  text-transform: uppercase;
  margin-bottom: 0.5em;
  font-size: 0.7em;
}

.PageGallery-checkbox input {
  margin-right: 1em;
}

.PageGallery-discordRoleToggle {
  margin-top: 2em;
}

.PageGallery-loading {
  font-weight: 300;
  font-family: "Press Start 2P";
  text-transform: uppercase;
}

.PageGallery-error {
  color: red;
}

.ReactModalPortal,
.ReactModal__Overlay {
  z-index: 1;
}

.ReactModal__Overlay {
  /* background-color: #000000bf !important; */
}

.PageGalleryModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fc0;
  box-shadow:
    0 1px 1px hsl(0deg 0% 0% / 8%),
    0 2px 2px hsl(0deg 0% 0% / 8%),
    0 4px 4px hsl(0deg 0% 0% / 8%),
    0 8px 8px hsl(0deg 0% 0% / 8%),
    0 16px 16px hsl(0deg 0% 0% / 8%);
  overflow: auto;
  outline: none;
  border-radius: 8px;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  width: 900px;
}

.PageGalleryModal-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 800px) {
  .PageGalleryModal-wrapper {
    grid-template-columns: 1fr;
  }
}

.PageGalleryModal-imgWrapper {
  position: relative;
  min-width: 336px;
  background-color: rgba(255, 255, 255, 0.25);
  aspect-ratio: 1;
}

.PageGalleryModal-img {
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .PageGalleryModal-imgWrapper::before {
    content: '';
    width: 100%;
    padding-top: 100%;
    min-width: 336px;
    height: auto;
    padding-left: 0;
    min-height: 0;
  }
}

.PageGalleryModal-metaWrapper {
  position: relative;
  min-width: 336px;
  padding: 20px 20px 0px;
}

.PageGalleryModal-sectionHeadline {
  font-weight: 300;
  font-family: "Press Start 2P";
  text-transform: uppercase;
  font-size: 0.66667em;
  margin-bottom: 0.5em;
}

.PageGalleryModal-name {
  font-weight: 300;
  font-family: "Press Start 2P";
  text-transform: uppercase;
  font-size: 1.333em;
  margin-bottom: 0.6667em;
}

.PageGalleryModal-sectionWrapper {
  margin-bottom: 1em;
}

.PageGalleryModal-traits {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5px;
  row-gap: 5px;
}

.PageGalleryModal-trait {
  border-radius: 8px;
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 300;
  font-family: "Press Start 2P";
  text-transform: uppercase;
}

.PageGalleryModal-traitLabel {
  font-size: 0.5em;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0.5em;
}

.PageGalleryModal-traitValue {
  font-size: 0.75em;
  letter-spacing: 0.05em;
}

.PageGalleryModal-traitPercent {
  font-weight: 900;
  font-family: "Lato";
  font-size: 0.75em;
  color: rgba(0, 0, 0, 0.9);
}

.PageGalleryModal-roleTraits .PageGalleryModal-trait {
  font-family: "Lato";
  text-transform: initial;
  font-weight: 500;
}

.PageGalleryModal-links .AnchorText {
  font-size: 0.67em;
  font-weight: 300;
  font-family: "Press Start 2P";
  text-transform: uppercase;
}

.PageGalleryModal-links .TextIcon::before {
  width: 1.5em;
  margin-right: 0.6667em;
}

.PageGalleryModal-close {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 300;
  font-family: "Press Start 2P";
  text-transform: uppercase;
  font-size: 1.333em;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 10px;
}