.App {
  position: relative;
  text-align: center;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.App-inner,
.App-main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.App-header,
.App-footer,
.App-main {
  position: relative;
}

.ButtBG {
  position: relative;
  overflow: hidden;
}

.ButtBG::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -200vw;
  bottom: 0;
  left: 0%;
  background-attachment: fixed;
  background-image: url("../media/skin-bg.jpg");
  background-size: auto 100%;
  background-repeat: repeat-x;
  background-position: 0 0;
  animation-name: bg-scroll;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  backface-visibility: hidden;
}

@media (prefers-reduced-motion) {
  .ButtBG::before {
    animation: none;
  }
}

@keyframes bg-scroll {
  from {
    /* transform: translateX(0); */
    background-position: 0vw 0;
  }

  to {
    /* transform: translateX(-100vw); */
    background-position: -100vh 0;
  }
}

.RainbowBG {
  position: relative;
  overflow: hidden;
}

.RainbowBG::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -300%;
  bottom: 0;
  left: 0%;
  background: linear-gradient(90deg,
      rgba(35, 129, 250, 0.667) 0%,
      rgba(255, 204, 0, 0.667) 5%,
      rgba(255, 0, 0, 0.333) 10%,
      rgba(35, 129, 250, 0.2) 15%,
      rgba(35, 129, 250, 0.1) 20%,
      rgba(35, 129, 250, 0.5) 25%,
      rgba(35, 129, 250, 0.4) 30%,
      rgba(35, 129, 250, 0.3) 35%,
      rgba(35, 129, 250, 0.2) 40%,
      rgba(35, 129, 250, 0.1) 45%,
      rgba(35, 129, 250, 0.667) 50%,
      rgba(255, 204, 0, 0.667) 55%,
      rgba(255, 0, 0, 0.333) 60%,
      rgba(35, 129, 250, 0.2) 65%,
      rgba(35, 129, 250, 0.1) 70%,
      rgba(35, 129, 250, 0.5) 75%,
      rgba(35, 129, 250, 0.4) 80%,
      rgba(35, 129, 250, 0.3) 85%,
      rgba(35, 129, 250, 0.2) 90%,
      rgba(35, 129, 250, 0.1) 95%,
      rgba(35, 129, 250, 0.5) 100%);
  /* background: linear-gradient(90deg,
      rgba(255, 0, 0, 0.667) 0%,
      rgba(255, 204, 0, 0.667) 0%,
      rgba(0, 204, 0, 0.667) 0%,
      rgba(0, 204, 204, 0.667) 0%,
      rgba(0, 0, 204, 0.667) 0%,
      rgba(255, 0, 204, 0.667) 0%,
    ); */
  animation-name: rainbow-bg-scroll;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  backface-visibility: hidden;
}

@media (prefers-reduced-motion) {
  .RainbowBG::before {
    animation: none;
  }
}

@keyframes rainbow-bg-scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}