.SectionMintHeader-headline {
  font-size: 2em;
  display: block;
  font-family: "Press Start 2P";
  text-transform: uppercase;
  margin-bottom: 0.6em;
  font-weight: 400;
}

.SectionMintHeader-subheadline {
  font-size: 1em;
  display: block;
  font-family: "Press Start 2P";
  text-transform: uppercase;
  margin-top: 0.6em;
  margin-bottom: 1.2em;
  font-weight: 400;
}

.SectionMintHeader-body {
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 1.5em;
}

.SectionMintHeader-body--important {
  font-size: 1.5em;
  max-width: none;
  margin-bottom: 1em;
}

.SectionMintHeader-body--listTitle {
  margin-bottom: 0.25em;
}

.SectionMintHeader-body--list {
  padding: 0;
  list-style: none;
}