.PageHome-headline {
  font-size: 2.5em;
  line-height: 1.5em;
  margin-bottom: 1em;
  font-family: "Press Start 2P";
  text-transform: uppercase;
  font-size: 1.333em;
  font-weight: 400;
}

.PageHome-blurb {
  margin-bottom: 1em;
}

.PageHome-section:last-child,
.PageHome-blurb:last-child {
  margin-bottom: 0;
}

.PageHome-section--intro {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: #FFFCF9;
  padding: 5vh 0 8vh;
}

.PageHome-section--intro .SectionMint {
  margin-top: 4em;
}

.PageHome-section--intro .PageHome-sectionMain {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  padding: 0 1em;
}

.PageHome-section--intro .PageHome-blurb {
  display: block;
  padding: 1em;
  background-color: #111;
  color: #FFFCF9;
  border-radius: 0 0 10px 10px;
  font-family: "Press Start 2P";
  font-size: 1.2em;
}

@media only screen and (max-width: 680px) {
  .PageHome-section--intro .PageHome-blurb {
    font-size: 0.8em;
  }
}

.PageHome-section--intro .PageHome-sectionMainInner {
  position: relative;
}

.PageHome-section--intro .PageHome-sectionMainInner::before,
.PageHome-section--intro .PageHome-sectionMainInner::after,
.PageHome-section--intro .SectionMint--connected::after {
  content: "";
  z-index: 1;
  display: block;
  position: absolute;
  font-family: "Press Start 2P";
  color: #000;
  animation-name: dumb-text;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate-reverse;
  backface-visibility: hidden;
}

.PageHome-section--intro .PageHome-sectionMainInner::before {
  content: "SO COOL!";
  right: 0%;
  top: 0%;
  font-size: 24px;
  transform: translate(40%) rotate(16deg);
  animation-name: dumb-text-1;
  animation-duration: 1.324s;
}

.PageHome-section--intro .PageHome-sectionMainInner::after {
  content: "WOW!";
  left: 0%;
  bottom: 20%;
  font-size: 48px;
  transform: translate(-40%) rotate(-16deg);
  animation-name: dumb-text-2;
  animation-duration: 1s;
}

.PageHome-section--intro .SectionMint {
  position: relative;
}

@media only screen and (max-width: 1130px) {

  .PageHome-section--intro .PageHome-sectionMainInner::before,
  .PageHome-section--intro .PageHome-sectionMainInner::after,
  .PageHome-section--intro .SectionMint--connected::after {
    font-size: 20px;
  }

  .PageHome-section--intro .PageHome-sectionMainInner::before {
    display: none;
  }

  .PageHome-section--intro .PageHome-sectionMainInner::after {
    top: -2%;
    bottom: initial;
    left: 5%;
  }
}

@media (prefers-reduced-motion) {

  .PageHome-section--intro .PageHome-sectionMainInner::before,
  .PageHome-section--intro .PageHome-sectionMainInner::after {
    animation: none;
  }
}

@keyframes dumb-text-1 {
  from {
    transform: translate(40%) rotate(16deg) scale(1);
  }

  to {
    transform: translate(40%) rotate(20deg) scale(1.25);
  }
}

@keyframes dumb-text-2 {
  from {
    transform: translate(-40%) rotate(-16deg) scale(1);
  }

  to {
    transform: translate(-40%) rotate(-18deg) scale(1.2);
  }
}

.PageHome-previewVideo {
  position: relative;
  display: block;
  aspect-ratio: 1.7777777778;
  width: 100%;
  max-width: 100%;
  border-radius: 10px 10px 0 0;
}

.PageHome-section--imageText {
  position: relative;
  padding: 5em 0;
  color: #000;
}

.PageHome-section--details {
  background-color: #2381fa;
  color: #FFFCF9;
}

.PageHome-section--imageText .PageHome-headline {
  margin-bottom: 0.5em;
}

.PageHome-section--imageText .PageHome-sectionMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.PageHome-section--imageText a,
.PageHome-section--imageText a:visited {
  color: #000;
}

.PageHome-section--details a,
.PageHome-section--details a:visited {
  color: #FFFCF9;
}

.PageHome-section--imageText .PageHome-sectionInner {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 1em;
}

.PageHome-section--imageText .PageHome-sectionInner {
  flex-direction: column-reverse;
}

.PageHome-sectionImg img {
  display: block;
  width: 300px;
  border-radius: 10px;
  border: 4px solid transparent;
  max-width: 100%;
}

.PageHome-section--details .PageHome-sectionImg img {
  border: 4px solid #FFFCF9;
}

.PageHome-section--legacy .PageHome-sectionImg img {
  border: 4px solid #000;
}

.PageHome-section--imageText .PageHome-sectionImg--preview {
  display: block;
  margin-bottom: 1.5em;
}

.PageHome-sectionImg--preview img {
  margin: auto;
}

@media only screen and (min-width: 680px) {
  .PageHome-section--imageText {
    text-align: left;
  }

  /* .PageHome-section--legacy {
    text-align: right;
  } */

  .PageHome-sectionImg--preview img {
    min-width: 300px;
  }

  .PageHome-section--imageText .PageHome-sectionInner {
    display: flex;
    flex-direction: row-reverse;
  }

  .PageHome-section--legacy .PageHome-sectionInner {
    flex-direction: row;
  }

  .PageHome-section--imageText .PageHome-sectionImg {
    margin: 0 2em 0 0;
  }

  .PageHome-section--legacy .PageHome-sectionImg {
    margin: 0 0 0 2em;
  }

  .PageHome-section--imageText .PageHome-sectionMain {
    align-items: flex-start;
  }

  /* .PageHome-section--legacy .PageHome-sectionMain {
    align-items: flex-end;
  } */

  .PageHome-headline {
    font-size: 2em;
    margin-bottom: 1em;
  }
}