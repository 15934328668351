.AppFooter {
  position: relative;
  background-color: #ffcc00;
  padding: 3em 1em 2em;
}

.AppFooterSection {
  font-size: 14px;
  margin-bottom: 1.5em;
}

.AppFooterSection:last-child {
  margin-bottom: 0;
}

.AppFooterSection--contact .AppFooterContactLink--projects {
  display: flex;
  justify-content: center;
}

.AppFooterSection--contact .ProjectLink {
  display: block;
}

.AppFooterSection--contact .ProjectLink--og {
  max-width: 180px;
  margin: 2px 0;
}

.AppFooterSection--contact .ProjectLink--v2 {
  max-width: 200px;
}

.AppFooterSection--contact .AppFooterContactLink--projects .ProjectLink {
  margin-left: 20px;
  padding-left: 20px;
  position: relative;
  display: block;
}

.AppFooterSection--contact .AppFooterContactLink--projects .ProjectLink::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 20%;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.2);
}

.AppFooterSection--contact .AppFooterContactLink--projects .ProjectLink:first-child {
  margin-left: 0;
  padding-left: 0;
}

.AppFooterSection--contact .AppFooterContactLink--projects .ProjectLink:first-child::before {
  display: none;
}

.AppFooterSection--contact {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 540px) {
  .AppFooterSection--contact {
    flex-direction: column;
  }
}

.AppFooterContactLink .AnchorText {
  white-space: nowrap;
  margin: 0 1em;
}

.AppFooterSection--contact .AppFooterContactLink .AnchorText {
  display: block;
}

.AppFooterSection--contact .AppFooterContactLink--general .AnchorText {
  margin: 0;
  display: inline;
}

.AppFooterSection--thanks .AppFooterContactLink .AnchorText {
  display: inline;
  margin: 0;
}

.AppFooterSection-legal {
  font-style: italic;
}