.AnchorWalletAddress {
  display: inline-block;
}
.AnchorWalletAddress-inner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.AnchorWalletAddress-identicon {
  margin-right: 1em;
}
.AnchorWalletAddress-info {
  text-align: left;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.AnchorWalletAddress-chain {
  display: block;
  font-weight: 700;
  text-align: left;
}
.AnchorWalletAddress-address {
  display: block;
  font-weight: 300;
}
.AnchorWalletAddress-disconnect {
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.667em;
  margin-top: 0.333em;
  letter-spacing: 0.12em;
  padding: 0;
  text-align: left;
}
