.AppHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* color: white; */
  color: rgb(12, 12, 13);
  padding: 2em 0 0;
  background-color: #FFFCF9;
  z-index: 1;
}

.AppHeader-link {
  font-size: 18px;
  min-width: 6em;
  display: block;
}

.AppHeader-logo {
  text-decoration: none;
  max-width: 694px;
  padding: 5px 10px;

  /* v2 logo hack */
  display: flex;
  justify-content: flex-end;
}

.AppHeader-logoImg {
  display: block;
  position: relative;

  /* v2 logo hack */
  width: 92.1%;
}

.AppHeader-logo .AppLogo-title {
  backface-visibility: hidden;
  transition: 0.2s transform ease;
}

.AppHeader-logo:focus,
.AppHeader-logo:hover {
  text-decoration: none;
}

.AppHeader-logo:focus .AppLogo-title,
.AppHeader-logo:hover .AppLogo-title {
  /* transform: scale(1.1); */
  transform: scale(1.1) translate(-50%, -50%);
}