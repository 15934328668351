.PageProvenance {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: left;
  background-color: #FFFCF9;
}

.PageProvenance-inner {
  width: 100%;
  max-width: 1000px;
  padding: 2em 1em;
  margin: 0 auto;
}

.PageProvenance code {
  word-break: break-all;
}

.PageProvenance-section {
  margin-bottom: 2em;
}

.PageProvenance-headline {
  margin-bottom: 1em;
  text-transform: uppercase;
}

.PageProvenance-blurb {
  margin-bottom: 1em;
}

.PageProvenance-codeBlockWrapper {
  overflow-y: scroll;
  height: 8em;
  border: 1px solid #aaa;
  padding: 0.5em 1em;
  overflow-x: hidden;
}

.PageProvenance-section:last-child,
.PageProvenance-blurb:last-child {
  margin-bottom: 0;
}

.PageProvenance-tableWrapper {
  overflow: scroll;
  max-height: 40em
}

.PageProvenance-table {
  width: 100%;
}

.TokenRow--isUnminted {
  /* text-decoration: line-through; */
}