.PageInfo {
  flex: 1;
  display: flex;
  background: linear-gradient(0deg, #fc0, #0000);
}

.PageInfo-inner {
  flex: 1;
  width: 100%;
  position: relative;
  /* background-color: rgba(255, 255, 255, 0.7); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em 1em;
}

.PageInfo-headline {
  margin-bottom: 0.5em;
  text-transform: uppercase;
  font-size: 1.667em;
}

.PageInfo-section {
  margin-bottom: 2.25em;
  margin-top: 2.25em;
}

.PageInfo-sectionHeadline {
  display: block;
  font-size: 0.8em;
  font-weight: 300;
  font-family: "Press Start 2P";
  text-transform: uppercase;
  margin-bottom: 0.6em;
}

.PageInfo-inputLabel .PageInfo-sectionHeadline {
  margin-bottom: 1em;
}

.PageInfo-extendedInput {
  border-radius: 8px;
  display: inline-block;
  background-color: #f2f2f2;
}

.PageInfo-extendedInputInner {
  background-color: #f2f2f2;
  padding: 0 1em 0 0.5em;
  font-size: 16px;
}

.PageInfo-input {
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  box-shadow: 0;
}

.PageInfoExampleImg {
  max-width: 300px;
  margin: 0 auto;
}