* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFCF9;
  font-size: 16px;
  letter-spacing: 0.05em;
  overflow-y: scroll;
  overflow-x: hidden;
}

code {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 0.8em;
}

/* AnchorText */
.AnchorText {
  cursor: pointer;
  background-color: transparent;
  border: transparent;
  font-weight: 700;
  color: inherit;
  white-space: nowrap;
}

.AnchorText:focus,
.AnchorText:hover {
  text-decoration: underline;
}

.TextIcon {
  white-space: nowrap;
}

.TextIcon::before {
  content: "";
  display: inline-block;
  background-size: contain;
  background-position: center;
  aspect-ratio: 1;
  width: 1em;
  margin: -0.2em 0.2em 0;
  vertical-align: middle;
  white-space: nowrap;
}

.TextIcon--discord::before {
  background-image: url("http://www.google.com/s2/favicons?domain=https://discord.com");
  margin: -0.3em 0.3em 0;
}

.TextIcon--twitter::before {
  background-image: url("http://www.google.com/s2/favicons?domain=https://twitter.com");
}

.TextIcon--opensea::before {
  background-image: url("http://www.google.com/s2/favicons?domain=https://opensea.io");
}

.TextIcon--ebisus::before {
  background-image: url("http://www.google.com/s2/favicons?domain=https://app.ebisusbay.com");
}

.TextIcon--pinata::before {
  background-image: url("http://www.google.com/s2/favicons?domain=https://www.pinata.cloud/");
}

/* Button */
.Button {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #FFFCF9;
  /* background-color: #ff1493; */
  background-color: #ff0000;
  transition: background 0.2s ease 0s;
  padding: 0.5em 26px;
  border-radius: 54px;
  overflow: hidden;
  border: 0;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-weight: 400;
  font-family: "Press Start 2P";
  height: 60px;
  text-decoration: none;
}

.Button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 150%;
  width: 200%;
  height: 100%;
  transform: skewX(-20deg);
  background-image: linear-gradient(to right,
      transparent,
      rgba(255, 255, 255, 0.25),
      transparent);
}

.Button:focus,
.Button:hover,
.Button--loading {
  /* background: #d30f78; */
  background-color: #dd0000;
  transition-delay: 0.25s;
}

.Button:focus::after,
.Button:hover::after,
.Button--loading::after {
  animation: button-shine 0.5s cubic-bezier(0.01, 0.56, 1, 1);
}

.Button--loading:focus::after,
.Button--loading:hover::after,
.Button--loading::after {
  animation-iteration-count: infinite;
}

@keyframes button-shine {
  100% {
    left: -200%;
  }
}

.Button--disabled,
.Button--disabled:hover,
.Button--disabled:focus {
  cursor: default;
  background-color: #edb8b8;
  color: #eee;
}

.Button--disabled:focus::after,
.Button--disabled:hover::after {
  animation: none;
  background-image: none;
}

.Button-text {
  position: relative;
  transition: opacity 0.25s;
  /* padding to account for font-family */
  padding-left: 0.3em;
  display: block;
}

.Button--loading .Button-text {
  opacity: 0;
}

.Button--loading::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2em;
  height: 2em;
  border-radius: 2em;
  border-style: solid;
  border-width: 4px;
  border-color: rgba(255, 255, 255, 1) rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0.5);
  animation: button-load 0.667s cubic-bezier(0.455, 0.835, 0.415, 0.555) infinite;
  transform-origin: top left;
  transform: translate(-50%, -50%);
}

@keyframes button-load {
  0% {
    transform: rotate(0deg) translate(-50%, -50%);
  }

  100% {
    transform: rotate(360deg) translate(-50%, -50%);
  }
}

/* ButtonText */
.ButtonText {
  cursor: pointer;
  background-color: transparent;
  border: transparent;
  font-weight: 700;
}

.ButtonText:focus,
.ButtonText:hover {
  text-decoration: underline;
}

/* Toggle */

.Toggle {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 1em;
  vertical-align: middle;
  font-size: 40px;
}

.Toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 1em;
}

.Toggle-slider:before {
  position: absolute;
  content: "";
  height: 0.8em;
  width: 0.8em;
  top: 0.1em;
  left: 0.1em;
  bottom: 0.1em;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.Toggle-input:checked+.Toggle-slider {
  background-color: #ff0000;
}

.Toggle-input:focus+.Toggle-slider {
  box-shadow: 0 0 2px #000000;
}

.Toggle-input:checked+.Toggle-slider:before {
  transform: translateX(1em);
}

.Toggle-label {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  text-indent: -100%;
}