.Paginator {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0em 0 1em;
  width: 100%;
}

.Paginator-inner {
  position: relative;
  width: 100%;
  margin: 1em auto 4em;
}

.Paginator-buttonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Paginator-button {
  font-weight: 300;
  font-family: "Press Start 2P";
  text-transform: uppercase;
  border: 0;
  background: transparent;
  cursor: pointer;
  border: 1px solid transparent;
  font-size: 2em;
  z-index: 1;
  padding: 0;
  letter-spacing: -0.2em;
}

.Paginator-button:disabled {
  opacity: 0.25;
  cursor: default;
}

.Paginator-pageLinks {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.Paginator-pageLink {
  font-weight: 300;
  font-family: "Press Start 2P";
  text-transform: uppercase;
  border: 0;
  background: transparent;
  cursor: pointer;
  border: 1px solid transparent;
  font-size: 0.75em;
}

.Paginator-pageLink--current {
  text-decoration: underline;
}

.PaginatorSelect {
  font-size: 1.5em;
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  vertical-align: middle;
  font-weight: 300;
  font-family: "Press Start 2P";
  text-transform: uppercase;
  padding: 0.25em;
  border: 1px solid #000;
  font-size: 1.5em;
  position: absolute;
  max-width: calc(100% - 6em)
}

.Paginator-option {
  font-size: 0.75em;
  appearance: none;
  padding: 0.25em;
  height: 2em;
}

.Paginator-option--current {
  font-weight: bold;
}